.input:focus-visible {
	border: 1px solid var(--main-color) !important;
	outline: none;
}

.error:focus-visible {
	border: 1px solid var(--red-color) !important;
	outline: none;
}

.inputDefault:focus-visible {
	border: 1px solid var(--stroke-color) !important;
	outline: none;
}