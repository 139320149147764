@tailwind base;
@tailwind components;
@tailwind utilities;

@import "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap";

html,
body {
	margin: 0;
	padding: 0;

	color: var(--text-color);
	background: var(--white-color);

	font-family: var(--font-family);
}

a {
	text-decoration: none;

	color: inherit;
}

* {
	box-sizing: border-box;
}

*:focus-visible {
	border: none;
	outline: none;
}

:root {
	--main-color: #1a1a1a;
	--bg-color: #fcfdfd;
	--light-gray-color: #f9fafb;
	--hover-color: #f2f4f7;
	--stroke-color: #eaecf0;
	--passive-text-color: #667085;
	--text-color: #101828;
	--white-color: #ffffff;
	--red-color: #f21414;
	--green-color: #28c76f;
	--font-family: "Inter", sans-serif;
}

.truncate-text {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.truncate-1-lines {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.truncate-2-lines {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.truncate-3-lines {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.clamp-text-1 {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	min-height: calc(1.5em * 1);
}

.clamp-text-3 {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	min-height: calc(1.5em * 3);
}

.clamp-text-4 {
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	padding: 0 1em;
	min-height: calc(
		1.5em * 4
	); /* Adjust based on your actual font-size and line-height */
}
