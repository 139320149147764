@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #1A1A1A; /* Dark grey - main color */
  border-radius: 50%;
  width: 35px;
  height: 35px;
  animation: spin 1.25s linear infinite;
}

.modalLoader {
  border: 3px solid #d6d6d6; /* Light grey */
  border-top: 3px solid #1A1A1A; /* Dark grey - main color */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1.25s linear infinite;
}
