.switch {
  position: relative;
  display: inline-block;
  width: 44px;   
  height: 24px;  
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--stroke-color);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;  
  width: 18px;   
  left: 3px;     
  bottom: 3px;   
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--main-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);  
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 24px;  
}

.slider.round:before {
  border-radius: 50%;
}
